import { GeoRegionDefinitionObject, GeoRegionObject } from '@x/ecommerce/domain-client';
import { IExpandedAddress } from '@x/geocode/client';
import { GeoRegionDefinitionType, PointInput, PolygonInput } from '@x/schemas/ecommerce';

export interface GeoRegionDefinitionViewModel {
  label: string;
  id: number;
  regionId: number;
  type: GeoRegionDefinitionType;
  include: boolean;
  country?: string | null;
  province?: string | null;
  postalCode?: string | null;
  city?: string | null;
  suburb?: string | null;
  boundary?: PolygonInput | null;
  center?: PointInput | null;
}

export interface GeoRegionIndexActiveFocus {
  addresses?: IExpandedAddress[];
  definition?: GeoRegionDefinitionViewModel;
}

export interface GeoRegionIndexStateFilter {
  type: 'ADDRESS' | 'BOUNDARY' | 'POSTCODE' | 'ALL';
  search: string;
}

export interface GeoRegionTreeViewModel {
  branch: GeoRegionObject;
  children: GeoRegionTreeViewModel[];
}

export interface GeoRegionIndexStateModel {
  init: false;
  loading: boolean;
  regions: GeoRegionObject[];
  regionTree: GeoRegionTreeViewModel[];
  definitions: GeoRegionDefinitionViewModel[];
  definitionsFiltered: GeoRegionDefinitionViewModel[];
  query: {
    channelId?: number;
    regionId?: number;
  };
  filter: GeoRegionIndexStateFilter;
  activeFocus: GeoRegionIndexActiveFocus | null;
  editingDefinition: boolean;
}

export interface MarkerViewModel {
  definition?: GeoRegionDefinitionViewModel;
  position: google.maps.LatLngLiteral;
  options: google.maps.MarkerOptions;
}

export interface PolygonViewModel {
  id: number;
  definition?: GeoRegionDefinitionViewModel;
  paths: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>;
  options: google.maps.PolygonOptions;
}

//#endregion

//#region viewmodels

export const defaultPolygonOptions = {
  fillColor: '#004998',
  fillOpacity: 0.25,
  strokeColor: '#004998',
  strokeOpacity: 1,
  strokeWeight: 0.5,
};
export const excludedPolygonOptions = {
  ...defaultPolygonOptions,
  fillColor: '#c00',
  strokeColor: '#c00',
};
export const defaultMarkerOptions = {};

export function createGeoRegionDefinitionViewModel(
  def: GeoRegionDefinitionObject,
): GeoRegionDefinitionViewModel {
  return {
    ...def,
    regionId: def.region.id,
    label: geoRegionDefinitionLabel(def),
  };
}

export function geoRegionDefinitionLabel(def: GeoRegionDefinitionObject) {
  switch (def.type) {
    case 'BOUNDARY':
      return '(Boundary)';
    case 'POSTCODE': {
      const label = [def.postalCode, def.city, def.country].filter((p) => !!p).join(', ');
      return label.length > 0 ? label : '(Postcode)';
    }
    case 'ADDRESS':
    default: {
      const label = [def.suburb, def.city, def.province, def.postalCode, def.country]
        .filter((p) => !!p)
        .join(', ');
      return label.length > 0 ? label : '(Address)';
    }
  }
}

export function createPolygonViewModel(
  definition: GeoRegionDefinitionViewModel,
  editable = false,
): PolygonViewModel | null {
  if (!definition.boundary) return null;

  const paths = formatPolygonForGoogle(definition.boundary);

  const options: google.maps.PolygonOptions = {
    ...(definition.include ? defaultPolygonOptions : excludedPolygonOptions),
    editable,
    draggable: editable,
  };

  return {
    id: definition.id,
    definition,
    paths,
    options,
  };
}

export function updatePolygonViewModel(
  polygon: PolygonViewModel,
  include: boolean,
  editable = false,
): PolygonViewModel | null {
  const options: google.maps.PolygonOptions = {
    ...polygon.options,
    ...(include ? defaultPolygonOptions : excludedPolygonOptions),
    editable,
    draggable: editable,
  };

  return {
    ...polygon,
    options,
  };
}

export function createMarkerViewModel(
  definition: GeoRegionDefinitionViewModel,
  editable = false,
): MarkerViewModel | null {
  return definition.center
    ? {
        definition,
        position: definition.center,
        options: {
          ...defaultMarkerOptions,
          draggable: editable,
        },
      }
    : null;
}

//#endregion

//#region poylgon formatting

export interface PointObject {
  lat: number;
  lng: number;
}
export interface PolygonObject {
  exterior: PointObject[];
  interior: PointObject[][];
}

export function formatPolygonForGoogle(
  polygon: PolygonObject,
): google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> {
  return new google.maps.MVCArray([
    new google.maps.MVCArray(formatPathForGoogle(polygon.exterior)),
    ...polygon.interior.map((interior) => new google.maps.MVCArray(formatPathForGoogle(interior))),
  ]);
}
function formatPathForGoogle(point: PointObject[]): google.maps.LatLng[] {
  const path = [...point];

  if (!path) {
    return [];
  }
  if (path.length < 2) {
    return path.map((p) => new google.maps.LatLng(p));
  }
  const first = path[0];
  const last = path[path.length - 1];
  const isAutoclosing = first.lat === last.lat && first.lng === last.lng;
  return (isAutoclosing && path.length > 2 ? [...path].slice(0, path.length - 1) : path).map(
    (l) => new google.maps.LatLng(l.lat, l.lng),
  );
}

export function formatPolygonForObject(
  polygon: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | null,
): PolygonObject {
  if (!polygon || polygon.getLength() === 0) {
    return {
      exterior: [],
      interior: [],
    };
  }
  const paths = [...polygon.getArray()];

  const exterior = paths.shift();
  return {
    exterior: exterior ? formatPathForObject(exterior) : [],
    interior: paths.map((i) => formatPathForObject(i)),
  };
}
function formatPathForObject(pathArr: google.maps.MVCArray<google.maps.LatLng>): PointObject[] {
  const path = pathArr.getArray();
  if (!path || path?.length < 1) {
    return path.map((p) => ({ lat: p.lat(), lng: p.lng() }));
  }
  return [...path, path[0]].map((p) => ({ lat: p.lat(), lng: p.lng() }));
}

//#endregion

//#region tree stuff

export function buildTreeFromArray(
  regions: GeoRegionObject[],
  parentId: number | undefined = undefined,
): Array<GeoRegionTreeViewModel> {
  let branches = regions.filter((region) => {
    return region.parent?.id === parentId;
  });

  return branches.map((branch) => {
    let children = buildTreeFromArray(regions, branch.id);
    return {
      branch,
      children,
    };
  });
}

//#endregion
